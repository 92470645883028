import { createRouter, createWebHistory } from 'vue-router'
const HomeView =()=> import('../views/HomeView.vue')
const AppointmentView =()=> import('../views/AppointmentView.vue')
const AboutView =()=> import('../views/AboutView.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/lets-work',
    name: 'apointment',
    component:AppointmentView
  },
 
  {
    path: '/about',
    name: 'about',
    component: AboutView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
